<template>
  <div>
    <!-- Address Field -->
    <template v-if="!isSelectedAddress">
      <!-- Address search -->
      <XeAddressSearch
        :dropdown-label="dropdownLabel"
        :is-manually-click="true"
        :show-manual-input-prompt="true"
        :country="country"
        :is-allow-free-input="true"
        :validation-error-message="addressErrorMessage"
        @input="$emit('input', $event)"
        @select="$emit('select', $event)"
      />
    </template>
    <!-- Selected Address -->
    <template v-else>
      <!-- Border -->
      <div class="px-6 py-4 mb-6 border border-solid rounded-2xl border-gray-darker">
        <!-- Container -->
        <div class="flex">
          <!-- Location icon -->
          <div class="flex flex-col">
            <span class="flex justify-center items-center bg-tertiary-blue rounded-full w-8 h-8">
              <AppIcon size="18" name="Location">
                <IconLocation class="text-main-cyan" />
              </AppIcon>
            </span>
          </div>
          <!-- Main content -->
          <div class="ml-2 flex-grow">
            <h3 class="type-subtitle-bold text-lg font-semibold text-main-black">
              {{ dropdownLabel }}
            </h3>
            <p class="pt-1 type-caption text-gray-address-gray">
              {{ value }}
            </p>
          </div>
          <!-- Edit button -->
          <div
            class="flex flex-col justify-center cursor-pointer"
            @keydown="$emit('address-clear')"
            @click="$emit('address-clear')"
          >
            <AppIcon name="Edit">
              <IconEdit class="text-gray-primary" />
            </AppIcon>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'

import XeAddressSearch from '@/components/XeAddressSearch/XeAddressSearch'
import AppIcon from '@/components/AppIcon/AppIcon'

import { IconEdit, IconLocation } from '@moneytransfer.ui/euronet-icons'

export default {
  components: {
    AppIcon,
    IconEdit,
    IconLocation,
    XeAddressSearch,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    country: {
      type: String,
      default: '',
    },
    dropdownLabel: {
      type: String,
      default: '',
    },
    isSelectedAddress: {
      type: Boolean,
      default: false,
    },
    validationMessage: {
      type: String,
      default: 'The address you entered is invalid. Please try again',
    },
    addressValidation: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Object],
      default: '',
    },
  },
  setup(props) {
    const addressErrorMessage = ref('')
    const formattedAddress = computed(() => props.value)

    watch(formattedAddress, (formattedAddress) => {
      validateAddress(formattedAddress)
    })

    const validateAddress = (address) => {
      const regex = new RegExp(props.addressValidation)
      const isValid = regex.test(address)
      if (isValid) {
        addressErrorMessage.value = ''
      } else {
        addressErrorMessage.value = props.validationMessage
      }
    }

    return {
      addressErrorMessage,
      formattedAddress,
    }
  },
}
</script>

<style lang="postcss" scoped></style>
