<template>
  <div class="w-full">
    <!-- Outer title -->
    <XeStpHeading
      v-if="isSummaryScreen || isOuterLayout"
      :heading="outerHeading"
      :sub-heading="outerSubHeading"
      is-outer
    />

    <!-- Card content -->
    <AppCard :size="'1/2xl'">
      <!-- Loader -->
      <div v-if="isLoadingOptions" class="text-center text-orange">
        <AppSpinner :size="32" :loading="isLoadingOptions"></AppSpinner>
      </div>

      <!-- Main content -->
      <template v-else>
        <!-- Summary Screen -->
        <template v-if="isSummaryScreen">
          <DashboardSummary :stepper-items="summaryItems" :page-name="pageName" @edit="onEdit" />
          <!-- Footer (Summary) - mobile device -->
        </template>

        <!-- Card content -->
        <template v-else>
          <!-- Inner heading -->
          <XeStpHeading
            v-if="!isOuterLayout"
            :heading="selectedContent.headline"
            :sub-heading="selectedContent.subHeadline"
          />

          <!-- List of requirements -->
          <ul
            v-if="selectedContent.informationList"
            class="type-caption text-gray list-disc pl-4 pt-3 sm:pt-5"
          >
            <li v-for="({ text }, index) in selectedContent.informationList" :key="index">
              {{ text }}
            </li>
          </ul>

          <!-- Form -->
          <form class="footer-right" @submit.prevent="submit(selectedField)">
            <!-- Field based on target stepper item -->
            <AppDynamicFormField
              v-if="selectedField"
              :class="[
                ...selectedFieldsClasses,
                selectedContent.id === 'registeredname' ? 'show-label' : null,
              ]"
              :field="selectedField"
            />

            <!-- Set custom message for disabled fields -->
            <div v-if="selectedField && selectedField.message" class="field-message">
              <AppIcon class="mr-1" size="12">
                <IconInformationFilled class="text-tertiary-text" />
              </AppIcon>
              {{ selectedField.message }}
            </div>

            <!-- Custom Fields -->
            <template>
              <!-- Sub-industry -->
              <AppDynamicFormField
                v-if="selectedField && selectedField.id === 'natureofbusiness' && industryData"
                :class="[industryData ? 'show-label' : null]"
                class="pt-4"
                :field="formFields.find((f) => f.id === 'industry')"
              />

              <!-- Company name / Trading name -->
              <template v-if="selectedContent.id === 'registeredname' && tradingNameToggle">
                <AppDynamicFormField
                  v-if="tradingNameField"
                  :field="tradingNameField"
                  class="show-label"
                />
              </template>

              <!-- Address -->
              <template v-if="selectedContent.id === 'registeredaddress'">
                <XeInputAddress
                  v-for="(item, index) in selectedContent.components"
                  :id="item.id"
                  :key="index"
                  v-model="addressContent[item.id].formattedAddressString"
                  :class="[
                    !operatingAddresstoggle && item.id === 'tradingaddress' ? 'hidden' : 'block',
                    item.class,
                  ]"
                  :dropdown-label="item.label"
                  :country="country"
                  :is-selected-address="addressContent[item.id].isSelectedAddress"
                  :address-validation="addressRegex"
                  @input="onInput({ id: item.id, address: $event })"
                  @select="onSelect({ id: item.id, address: $event })"
                  @address-clear="onAddressClear({ id: item.id })"
                />
              </template>
            </template>

            <!-- Disclaimer -->
            <div v-if="selectedContent.disclaimer" class="sub-card mb-4">
              <AppInputCheckbox v-model="disclaimer" class="sub-card-left" />
              <div class="sub-card-right">
                <p class="type-caption text-gray">
                  {{ selectedContent.disclaimer.text }}
                </p>
              </div>
            </div>

            <!-- Toggle -->
            <div v-if="selectedContent.toggle" class="flex pt-5 pb-5">
              <AppInputToggle
                v-if="selectedContent.id === 'registeredname'"
                v-model="tradingNameToggle"
                class="input-toggle"
              ></AppInputToggle>
              <AppInputToggle
                v-else
                v-model="operatingAddresstoggle"
                class="input-toggle"
              ></AppInputToggle>
              <div class="pl-4">
                {{ selectedContent.toggle.text }}
              </div>
            </div>

            <!-- Footer (Main) -->
            <DashboardCompanyDetailsFooter
              v-if="!isOuterLayout"
              class="z-50 mb-0"
              :button-disabled="isSubmitButtonDisabled"
              button-type="submit"
              button-label="Save & Continue"
              is-card-footer
            />
          </form>
        </template>
      </template>
    </AppCard>

    <!-- Outer footer -->
    <DashboardCompanyDetailsFooter
      v-if="isOuterButton"
      :class="mq.current === 'xs' ? 'z-50 mb-0' : 'submit-button-wrapper'"
      :loading="loading"
      :button-disabled="isOuterLayout ? isSubmitButtonDisabled : loading"
      :button-label="isSummaryScreen ? 'Save & Continue' : 'Save & Finish'"
      :is-card-footer="isOuterButton"
      @click="isOuterLayout ? submit(selectedField) : goToPendingActions()"
    />
  </div>
</template>

<script>
import { useAddressFormat } from '@/composables/useAddress'
import { extractStreetNameAndStreetType } from '@/composables/useAddress'
import { useMediaQuery } from '@en-ui/composables/useMediaQuery'
import { useRouter } from '@/composables/useRouter'
import { useCompanyDetailsForm } from '@/forms/CompanyDetailsForm'
import { computed, onMounted, reactive, ref, watch } from '@vue/composition-api'

import AppCard from '@/components/AppCard/AppCard'
import AppDynamicFormField from '@/components/AppDynamicFormField/AppDynamicFormField'
import AppIcon from '@/components/AppIcon/AppIcon'
import AppInputCheckbox from '@/components/AppInputCheckbox/AppInputCheckbox'
import AppInputToggle from '@/components/AppInputToggle/AppInputToggle'
import XeInputAddress from '@/components/XeInputAddress/XeInputAddress'
import AppSpinner from '@/components/AppSpinner/AppSpinner'

import { IconInformationFilled } from '@moneytransfer.ui/euronet-icons'

import DashboardSummary from '../DashboardSummary/DashboardSummary.vue'
import XeStpHeading from '@/components/XeStpHeading/XeStpHeading.vue'
import DashboardCompanyDetailsFooter from './DashboardCompanyDetailsFooter.vue'
import { storeToRefs } from 'pinia'
import { addressFormatString } from '@/utils/address'
import { useAnalyticsStore } from '@/stores/analytics'
import { useDashboardStore } from '@/stores/dashboard'
import { useCompanyDetailsStore } from '@/stores/companyDetails'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { useCountriesStore } from '@/stores/countries'
import { companyIndex, isBvdData } from '@/helpers/companyDetails'

export default {
  components: {
    AppCard,
    AppDynamicFormField,
    AppInputToggle,
    AppInputCheckbox,
    XeInputAddress,
    AppSpinner,
    DashboardSummary,
    AppIcon,
    IconInformationFilled,
    XeStpHeading,
    DashboardCompanyDetailsFooter,
  },
  setup() {
    const router = useRouter()
    const analyticsStore = useAnalyticsStore()
    const dashboardStore = useDashboardStore()
    const companyDetailsStore = useCompanyDetailsStore()
    const corporateRegistrationStore = useCorporateRegistrationStore()
    const countriesStore = useCountriesStore()

    // Default values
    const corpProfile = computed(() => dashboardStore.corpProfile)
    const country = computed(() => corpProfile.value?.registrationAddress?.country || '')
    const pageName = ref('companyDetails')
    const stepperItems = computed(() => {
      let temp = dashboardStore.getStepperItems(pageName.value)
      // update 'Registered Number' text based on region
      if (['AU', 'NF'].includes(country.value) && temp[2].id === 'registrationnumber') {
        temp[2].name = 'ABN/ACN'
      } else if (['NZ', 'CK'].includes(country.value) && temp[2].id === 'registrationnumber') {
        temp[2].name = 'NZBN'
      }
      return temp
    })

    const operatingAddresstoggle = ref(false)
    const tradingNameToggle = ref(false)
    // Trading name field (optional and hidden)
    const tradingNameField = ref(null)
    const disclaimer = ref(false)
    const isAddressReady = ref(true)
    const isLoadingOptions = ref(true)
    const isEditing = ref(false)
    const mq = useMediaQuery()
    const mockedCompanyDetails = dashboardStore.mockedCompanyDetails
    const planToUseAccountValidation = ref(false)
    // items for summary page, calculate disabled flag from mockData
    const summaryItems = computed(() =>
      stepperItems.value.map((eachStepperItem) => {
        const isFieldDisabled = !!mockedCompanyDetails.fields.find(
          (eachField) => eachField.id === eachStepperItem.id
        )?.disabled
        return { ...eachStepperItem, disabled: isFieldDisabled }
      })
    )
    const addressRegex = computed(() => {
      const regex = countriesStore.getCountryConfigByCountryCode(country.value)
      return regex.addressRegex
    })

    // Get cached company details values
    const stepValues = computed(() => dashboardStore.companyDetails)
    const getAddresses = computed(() => dashboardStore.addressCompanyDetails)

    const { natureOfBusinessList, subIndustryList } = storeToRefs(corporateRegistrationStore)

    // Populate industry, sub-industry and company type dropdown
    const companyTypeList = computed(() => companyDetailsStore.companyTypeList)
    const industryName = computed(() => {
      const [item] = natureOfBusinessList.value.filter(
        (item) => item.id === getFieldValue('natureofbusiness')
      )
      return item.name
    })
    const subIndustryName = computed(() => {
      const [item] = subIndustryList.value.filter((item) => item.id === getFieldValue('industry'))
      return item.name
    })

    // Code to handle how things look and work on the summary screen and the "How Did You Hear About Us?" step
    const isOuterLayout = computed(() => selectedContent.value.id === 'planToUseAccount')
    const isSummaryScreen = computed(() => hasItemsFilled.value && !isEditing.value)
    const outerHeading = computed(() =>
      isOuterLayout.value ? selectedContent.value.headline : 'Confirm company details'
    )
    const outerSubHeading = computed(() =>
      isOuterLayout.value ? selectedContent.value.subHeadline : ''
    )
    const selectedFieldsClasses = computed(() => [
      selectedField.value.id === 'natureofbusiness' ? 'show-label' : null,
      isOuterLayout.value ? null : 'pt-10',
    ])
    const isOuterButton = computed(() => {
      return (
        (mq.current === 'xs' && isOuterLayout.value) || isOuterLayout.value || isSummaryScreen.value
      )
    })

    // const isVatNumberRequired = computed(() =>
    //   stepperItems.value.find((cd) => cd.id === 'vatNumber')
    // )
    // users are locked from editing VAT if it's found in BvD
    const disableVatNumberEdit = computed(
      // temporarily making VAT optional
      // https://euronet.atlassian.net/browse/MT-36447
      // () => isVatNumberRequired.value && store.state.dashboard.bvdData?.vatNumber !== ''
      () => dashboardStore.bvdData?.vatNumber !== ''
    )
    const disableIncDateEdit = computed(
      () =>
        dashboardStore.companyDetails.incorporationdate !== null ||
        dashboardStore.companyDetails.incorporationdate !== ''
    )
    // Default values from registered and trading address
    const addressContent = reactive({
      registeredaddress: {
        isSelectedAddress: false,
        isAddressReady: false,
        formattedAddressString: '',
        companyAddress: {},
      },
      tradingaddress: {
        isSelectedAddress: false,
        isAddressReady: false,
        formattedAddressString: '',
        companyAddress: {},
      },
    })
    // List of filled items
    const hasItemsFilled = computed(() => {
      const stepsCompletedness = stepperItems.value.map((step) => step.isItemChecked || false)
      return stepsCompletedness.every((x) => !!x)
    })
    // Sync stepper active item with content
    const selectedContent = computed(() => {
      const activeItem = stepperItems?.value?.find((item) => item.activeItem)
      if (activeItem) {
        return activeItem.content.itemsContent.find((e) => e)
      }
      return {}
    })
    // Select field checking active item
    const selectedField = computed(() => {
      // Check total steps in page
      const activeItem = stepperItems?.value?.find((item) => item.activeItem)

      if (activeItem) {
        const field = formFields?.value?.find((field) => field.id === activeItem.id)
        return field
      }
      return false
    })

    // Set up Fields
    const { setup: setupFields, formFields } = useCompanyDetailsForm()

    const setupCompanyDetailsFields = () => {
      // Create fields instance
      if (mockedCompanyDetails) {
        setupFields(mockedCompanyDetails)
        // we should use stepper Item as SoT
        formFields.value.forEach((field, i) => {
          // Set disabled fields
          let disableIncDate = disableIncDateEdit.value && field.id === 'incorporationdate'
          let disableVat = disableVatNumberEdit.value && field.id === 'vatNumber'

          // Disable fields
          if (
            isBvdData(field, dashboardStore.bvdData) || // if value match with bvd data
            disableIncDate ||
            disableVat ||
            mockedCompanyDetails.fields[i].disabled
          ) {
            field.disabled = true
            field.message = mockedCompanyDetails.fields[i].message
          }
          if ((disableIncDate || disableVat) && !field.value) {
            // Enabled editing
            field.disabled = false
            field.message = null
          }

          // Listeners
          formFields.value[i]['listeners'] = {
            inputListError: (evt) => {
              planToUseAccountValidation.value = evt
            },
          }
        })

        // Added field
        const tradingNameFieldIndex = formFields.value.findIndex(
          (field) => field.id === 'tradingname'
        )
        tradingNameField.value = formFields.value[tradingNameFieldIndex] || null
      }
    }

    // Pending Action Started | Trigger when form is not filled yet
    if (!isSummaryScreen.value) {
      analyticsStore.track({
        event: 'Pending Action Started',
        traits: {
          ActionName: 'Company Details',
        },
      })
    }

    // Populate when user hit path
    watch(pageName, async () => await populateCompanyDetails())
    // Populate company details data
    const populateCompanyDetails = async () => {
      const mustSaveSteps = ['registeredaddress', 'registeredname']
      if (Object.keys(stepValues.value).length === 0) {
        // Create company details object
        let companyDetails = {}
        // we must use stepperItems as the only SoT in the future
        stepperItems.value.forEach((item) => (companyDetails[item.id] = null))
        companyDetails = {
          ...companyDetails,
          tradingaddress: null,
          industry: null,
        }

        /******
          Pre populate using bvd or corp profile
        *******/

        // Company name
        companyDetails.registeredname =
          dashboardStore.bvdData?.companyName || corpProfile.value.companyDetails.registeredName
        // Company type
        const companyTypeId = corpProfile.value.companyDetails.companyTypeId
        companyDetails.companytype = companyTypeList.value.find(
          (cType) => cType.id === companyTypeId.toString()
        )?.name
        // Registration number
        companyDetails.registrationnumber =
          corpProfile.value.companyDetails.registrationNumber || ''
        // Date of incorporation
        companyDetails.incorporationdate = corpProfile.value.companyDetails.incorporationDate
        // Website
        if (corpProfile.value.companyDetails?.website) {
          companyDetails.website = corpProfile.value.companyDetails.website
        } else if (dashboardStore.bvdData?.website) {
          companyDetails.website = dashboardStore.bvdData.website[0]
        } else {
          companyDetails.website = null
        }
        // VAT number
        if (disableVatNumberEdit.value) {
          companyDetails.vatNumber = dashboardStore.bvdData?.vatNumber || ''
          mockedCompanyDetails.fields[
            companyIndex(mockedCompanyDetails, 'vatNumber')
          ].disabled = true
        }
        // Registration address
        let profileAddress = corpProfile.value.registrationAddress
        let isFixedFormat = Boolean(profileAddress?.fixedFormat)
        let formattedAddressString =
          Object.keys(profileAddress).length > 0
            ? addressFormatString(country.value, profileAddress, isFixedFormat)
            : null
        if (isFixedFormat) {
          addressContent.registeredaddress.companyAddress.fixedFormat = profileAddress.fixedFormat
        } else {
          addressContent.registeredaddress.companyAddress.freeFormat = profileAddress?.freeFormat
        }
        addressContent.registeredaddress.formattedAddressString = formattedAddressString
        addressContent.registeredaddress.isSelectedAddress = true
        addressContent.registeredaddress.isAddressReady = true
        companyDetails.registeredaddress = formattedAddressString
        // trading address
        let formattedAddress_ta = ''
        let profileTradingAddress = corpProfile.value.tradingAddress
        if (profileTradingAddress && Object.keys(profileTradingAddress).length > 0) {
          formattedAddress_ta = addressFormatString(
            country.value,
            profileTradingAddress?.freeFormat ? profileTradingAddress : profileAddress,
            isFixedFormat
          )
          if (isFixedFormat) {
            addressContent.tradingaddress.companyAddress.fixedFormat =
              profileTradingAddress?.fixedFormat || profileAddress.fixedFormat
          } else {
            addressContent.tradingaddress.companyAddress.freeFormat =
              profileTradingAddress?.freeFormat || profileAddress?.freeFormat
          }
        } else {
          if (isFixedFormat) {
            addressContent.tradingaddress.companyAddress.fixedFormat =
              addressContent.registeredaddress.companyAddress?.fixedFormat
          } else {
            addressContent.tradingaddress.companyAddress.freeFormat =
              addressContent.registeredaddress.companyAddress?.freeFormat
          }
          formattedAddress_ta = formattedAddressString
        }
        addressContent.tradingaddress.formattedAddressString = formattedAddress_ta
        addressContent.tradingaddress.isSelectedAddress = true
        addressContent.tradingaddress.isAddressReady = true
        companyDetails.tradingaddress = formattedAddress_ta

        dashboardStore.addressCompanyDetails = addressContent
        // Set stepper items
        for (const key in companyDetails) {
          // Clear undefined values
          if (companyDetails[key] === undefined) {
            companyDetails[key] = null
          }

          if (
            // eslint-disable-next-line no-prototype-builtins
            (companyDetails.hasOwnProperty(key) &&
              companyDetails[key] !== null &&
              companyDetails[key] !== undefined) ||
            key === 'registrationnumber'
          ) {
            await dashboardStore.setStepperValue({
              itemId: key,
              targetKey: pageName.value,
              value: stepperValue({ id: key, value: companyDetails[key] }),
              setChecked: !mustSaveSteps.includes(key),
            })
          }
          // Store object
          dashboardStore.companyDetails = companyDetails
        }
      }
      await setupCompanyDetailsFields()
      isLoadingOptions.value = false
    }

    // Check values to toggle switches
    const setFormSwitches = () => {
      const saveValues = dashboardStore.companyDetails
      // Show trading address if exists and is != from registered address
      operatingAddresstoggle.value =
        !!saveValues.tradingaddress && saveValues.tradingaddress !== saveValues.registeredaddress
      tradingNameToggle.value = !!saveValues.tradingname
    }

    // Setup all required data to pre populate fields
    onMounted(async () => {
      // Populate address search components
      if (Object.keys(getAddresses.value).length > 0) {
        addressContent.registeredaddress = getAddresses.value.registeredaddress
        addressContent.tradingaddress = getAddresses.value.tradingaddress
      }
      // Call company type endpoint
      if (companyTypeList.value.length === 0) {
        await companyDetailsStore.getCompanyTypeList(country.value)
      }
      // Call industry endpoint
      if (natureOfBusinessList.value.length === 0) {
        await corporateRegistrationStore.fetchNatureOfBusinessList()
      }
      // populateCompanyDetails()
      if (Object.keys(stepValues.value).length > 0) {
        // im leaving this line out as a comment bc i dont fully understand why we r double loading here
        // is this really necessary?
        // await setupCompanyDetailsFields()

        isLoadingOptions.value = false
      }
      // Populate value when user go from pending actions
      if (stepperItems.value) {
        await populateCompanyDetails()
      }

      // Set form switches (name and address)
      setFormSwitches()
    })
    /*
      Create custom field based on specific business logic
      1. Sub industry
    */
    // Gets industry data
    const industryData = computed(() => getValueFromField('natureofbusiness', 'natureofbusiness'))
    const subIndustryField = computed(() => getSpecificField('industry'))
    // Get field value from form fields
    const getFieldValue = (id) => {
      const field = formFields.value.find((field) => field.id === id)
      if (field && field.value) {
        return field.value
      }
      return ''
    }
    // Get specific field from form field object
    const getSpecificField = (id) =>
      formFields.value.find((field) => {
        return field.id === id
      })
    // Get specific value from field object
    const getValueFromField = (fieldName, fieldId) => {
      if (getSpecificField(fieldName)) {
        return getSpecificField(fieldName).props.options.find(
          (option) => option.id === getFieldValue(fieldId)
        )
      }
    }
    // Handle Sub Industry updates with the industry data
    watch(industryData, async (field, oldField) => {
      // Checks for updated field
      if (field) {
        subIndustryField.value.props.loading = true
        if ((isEditing.value || !hasItemsFilled.value) && field?.id !== oldField?.id) {
          await corporateRegistrationStore.setSubIndustryList(field.id)
          subIndustryField.value.props.options = subIndustryList.value
        }
        subIndustryField.value.disabled = false
        subIndustryField.value.props.loading = false
      } else {
        subIndustryField.value.disabled = false
      }
    })
    /*
      2. Registered address and trading address
    */
    // Set value selected on address component
    const onSelect = async ({ id, address }) => {
      address.provinceName = address.provinceName.replace(/Wp\s/, '')
      let state = await corporateRegistrationStore.mapStateValue(address.provinceName)
      // set state to null if not mapped correctly
      const stateLength = !state ? state.length : 0
      state = stateLength <= 3 ? state : null
      const [streetName, streetType] = extractStreetNameAndStreetType(address.street)
      if (corpProfile.value.registrationAddress?.fixedFormat) {
        addressContent[id]['companyAddress']['fixedFormat'] = {
          unit: address.subBuilding,
          streetNumber: address.buildingNumber,
          streetName: streetName,
          streetType: streetType,
          place: address.city,
          area: address.district,
          state: state || address.provinceName,
          postalCode: address.postCode,
        }
      } else {
        addressContent[id]['companyAddress']['freeFormat'] = {
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          addressLine3: address.addressLine3,
          place: address.city,
          county: state || address.provinceName,
          city: address.city,
          state: state,
          postalCode: address.postCode,
        }
      }
      addressContent[id]['formattedAddressString'] = useAddressFormat(address)
      addressContent[id]['isSelectedAddress'] = true
      addressContent[id]['isAddressReady'] = true
    }
    // Clear value selected on address component
    const onAddressClear = ({ id }) => {
      addressContent[id]['companyAddress'] = {}
      addressContent[id]['formattedAddressString'] = ''
      addressContent[id]['isSelectedAddress'] = false
      addressContent[id]['isAddressReady'] = false
    }
    // Save input value from address component
    const onInput = ({ id, address }) => {
      const trimmedAddress = address.trim()
      addressContent[id]['formattedAddressString'] = trimmedAddress
    }

    // Address validation
    const addressValidation = computed(() => {
      if (operatingAddresstoggle.value) {
        return (
          !addressContent.registeredaddress.isAddressReady ||
          !addressContent.tradingaddress.isAddressReady
        )
      } else {
        return !addressContent.registeredaddress.isAddressReady
      }
    })

    //
    watch(disclaimer, (status) =>
      status
        ? (getSpecificField('website').disabled = true)
        : (getSpecificField('website').disabled = false)
    )

    // Sub industry validation
    const subIndustryValidation = computed(() => {
      return getSpecificField('industry')?.validation?.$anyInvalid || false
    })

    // Global validation
    const isSelectedContent = (id) => selectedContent?.value?.id === id
    const isSubmitButtonDisabled = computed(() => {
      if (isSelectedContent('registeredaddress')) {
        return addressValidation.value
      } else if (isSelectedContent('natureofbusiness')) {
        const validation = subIndustryValidation.value
        return selectedField.value?.validation?.$anyInvalid || validation
      } else if (isSelectedContent('website')) {
        return !disclaimer.value ? selectedField.value?.validation?.$anyInvalid : false
      } else if (isSelectedContent('planToUseAccount')) {
        return selectedField.value?.validation?.$anyInvalid || planToUseAccountValidation.value
      } else {
        return selectedField.value?.validation?.$anyInvalid || false
      }
    })

    // Set stepper value
    const stepperValue = ({ field, value, id }) => {
      // Format value to display in the stepper
      if (field?.id === 'registeredaddress' || id === 'registeredaddress') {
        return addressContent.registeredaddress.formattedAddressString
      }
      if (field?.id === 'natureofbusiness' || id === 'natureofbusiness') {
        return `${industryName.value}, ${subIndustryName.value}`
      }
      if (field?.id === 'website' && disclaimer.value) {
        return ''
      }
      if (field?.id === 'planToUseAccount') {
        return field.value.map((d) => d.checkedValue).join(', ')
      }
      if (field?.id === 'registeredname') {
        if (!tradingNameToggle.value) {
          // Switch is off, clear value
          tradingNameField.value.value = ''
        } else if (!tradingNameField.value.value) {
          // Switch ON but no value, turn off
          tradingNameToggle.value = false
        }
      }
      return (field?.value || value) ?? ''
    }

    // Save company details values
    const saveCompanyDetails = async (field) => {
      if (field.id === 'registeredaddress') {
        const {
          registeredaddress: { formattedAddressString: regAddress },
          tradingaddress: { formattedAddressString: operateAddress },
        } = addressContent
        // Save address values
        await dashboardStore.setCompanyDetailsValue({
          id: 'registeredaddress',
          value: regAddress,
        })
        await dashboardStore.setCompanyDetailsValue({
          id: 'tradingaddress',
          value: operatingAddresstoggle.value ? operateAddress : regAddress,
        })
        // Set registered as trading address
        if (!operatingAddresstoggle.value) {
          addressContent.tradingaddress = { ...addressContent.registeredaddress }
        }
        // Store address object
        dashboardStore.addressCompanyDetails = addressContent
        analyticsStore.track({
          event: 'Business address added',
          traits: {
            registeredaddress: regAddress,
            tradingaddress: operatingAddresstoggle.value ? operateAddress : regAddress,
          },
        })
      } else if (field.id === 'natureofbusiness') {
        // Save Industry and Sub-industry
        await dashboardStore.setCompanyDetailsValue({
          id: field.id,
          value: field.value,
        })
        await dashboardStore.setCompanyDetailsValue({
          id: 'industry',
          value: getFieldValue('industry'),
        })
        analyticsStore.track({
          event: 'Industry added',
          traits: {
            industry: industryName.value,
            subindustry: subIndustryName.value,
          },
        })
      } else if (field.id === 'website') {
        // Save website
        await dashboardStore.setCompanyDetailsValue({
          id: field.id,
          value: disclaimer.value ? '' : field.value,
        })
        analyticsStore.track({
          event: 'Website added',
          traits: {
            website: disclaimer.value ? '' : field.value,
          },
        })
      } else if (field.id === 'registeredname') {
        // Saving trading name, if it is entered (and if switch is ON)
        await dashboardStore.setCompanyDetailsValue({
          id: 'tradingname',
          value: tradingNameToggle.value ? getFieldValue('tradingname', formFields) || null : null,
        })
        await dashboardStore.setCompanyDetailsValue({
          id: 'registeredname',
          value: getFieldValue('registeredname', formFields) || null,
        })
      } else {
        await dashboardStore.setCompanyDetailsValue({
          id: field.id,
          value: field.value,
        })
        let eventProps = {}
        switch (field.id) {
          case 'heardAboutUs':
            eventProps['source'] = field.value
            analyticsStore.track({
              event: 'Acquisition source completed',
              traits: eventProps,
            })
            break
          case 'planToUseAccount':
            eventProps['products'] = field.value.map((d) => d.value).join(', ')
            field.value.forEach((option) => {
              eventProps[option.value] = true
            })
            analyticsStore.track({
              event: 'Xe Products Selected',
              traits: eventProps,
            })
            break
          case 'registeredname':
            analyticsStore.track({
              event: 'Company name added',
              traits: {
                CompanyName: field.value,
              },
            })
            break
          case 'companytype':
            analyticsStore.track({
              event: 'Company type added',
              traits: {
                CompanyName: field.value,
              },
            })
            break
          case 'registrationnumber':
            analyticsStore.track({
              event: 'Registration number added',
              traits: {
                CompanyName: field.value,
              },
            })
            break
          case 'incorporationdate':
            analyticsStore.track({
              event: 'Date of incorporation added',
              traits: {
                CompanyName: field.value,
              },
            })
        }
      }
    }

    const loading = ref(false)

    const goToPendingActions = async () => {
      // todo
      // check if get profile is the same as the company details we have rn
      // if true then no need to call put

      try {
        loading.value = true
        await dashboardStore.putCorpProfile()
        let companyDetailsTraits = dashboardStore.companyDetails
        companyDetailsTraits = {
          ...companyDetailsTraits,
          planToUseAccount: companyDetailsTraits['planToUseAccount'].map((d) => d.value).join(', '),
        }

        analyticsStore.track({
          event: 'Company details confirmed',
          traits: {
            CompanyDetails: companyDetailsTraits,
          },
        })
        router.push({ name: 'PendingActions' })
      } catch (error) {
        router.push({
          name: 'PendingActionError',
          props: {
            errorType: error,
          },
        })
      } finally {
        loading.value = false
      }
    }

    const onEdit = (isEdit) => {
      isEditing.value = isEdit
    }

    // Submit question and stepper position
    const submit = async (field) => {
      // Save company details
      await saveCompanyDetails(field || selectedContent.value)
      // Save stepper
      await dashboardStore.setStepperValue({
        itemId: selectedContent.value.id,
        targetKey: pageName.value,
        value: stepperValue({ field: field || selectedContent.value }),
        setChecked: true,
      })
      isEditing.value = false
    }

    return {
      pageName,
      stepValues,
      stepperItems,
      tradingNameToggle,
      operatingAddresstoggle,
      disclaimer,
      hasItemsFilled,
      selectedContent,
      formFields,
      tradingNameField,
      selectedField,
      isSubmitButtonDisabled,
      industryData,
      submit,
      country,
      addressContent,
      onInput,
      onSelect,
      onAddressClear,
      isAddressReady,
      goToPendingActions,
      isLoadingOptions,
      isEditing,
      onEdit,
      mq,
      summaryItems,
      loading,
      addressRegex,
      isOuterLayout,
      isSummaryScreen,
      outerHeading,
      outerSubHeading,
      selectedFieldsClasses,
      isOuterButton,
    }
  },
}
</script>

<style lang="postcss" scoped>
.type-stp-confirm-title {
  @apply pb-6 pt-6 text-xl px-4;

  @screen sm {
    @apply text-2xl px-0;
  }

  @screen md {
    @apply text-3xl font-semibold max-w-1/2xl m-auto pb-12;
  }
}

.type-stp-title {
  @apply text-xl;

  @screen sm {
    @apply text-2xl;
  }

  @screen md {
    @apply text-3xl;
  }
}

>>> .card-content {
  @apply pt-2 px-4 pb-12;

  @screen sm {
    @apply pt-12 px-12;
  }

  .card-content-block {
    @apply mb-0;
  }
}

.sub-card {
  @apply flex items-center;
  @apply border-blue-lighter border rounded-2xl;
  @apply py-6;

  .sub-card-left {
    @apply pl-4;

    >>> .checkbox {
      border-color: rgba(179, 184, 194, 1);
    }
  }

  .sub-card-right {
    @apply pl-3;
  }
}

.show-label {
  >>> .input-label {
    @apply block;
  }
}

>>> .input-label {
  @apply hidden;
}

.input-toggle {
  min-width: 42px;

  >>> .halo {
    @apply bg-main-cyan opacity-10 !important;
  }
}

>>> .on {
  @apply bg-main-cyan !important;
}

.field-message {
  @apply flex items-center type-caption -mt-4 pb-8 text-tertiary-text;
}

.submit-button-wrapper {
  @apply hidden;

  @screen sm {
    @apply flex justify-end mt-6;
  }

  @screen md {
    @apply mt-10 max-w-1/2xl mx-auto;
  }
}
</style>
