import { fieldsAllowedToGetBvdData } from '@/constants/companyDetails'
import { constantToString } from '@/utils/string'

export const companyIndex = (data, id) => data.fields.findIndex((cd) => cd.id === id)

// ** Check if bvd data match with field value
export const isBvdData = (field, bvdData) => {
  const allowedFields = Object.keys(fieldsAllowedToGetBvdData).map((item) =>
    constantToString(item, 'lower')
  )
  const allowedBvdIds = Object.values(fieldsAllowedToGetBvdData)

  if (allowedFields.includes(field.id.toLocaleLowerCase())) {
    const index = allowedFields.findIndex((i) => i === field.id.toLocaleLowerCase())
    const bvdValue = bvdData[allowedBvdIds[index]]

    // Check by type

    // Array
    if (Array.isArray(bvdValue)) {
      return bvdValue[0] === field.value
    }
    // Date
    if (Date.parse(bvdValue)) {
      return bvdValue.includes(field.value)
    }
    return bvdValue === field.value
  }
}

export const regNumberBeforeDoi = (fields) => {
  let indexDoi, indexRegnum;
  fields.forEach((field, index) => {
    if (field.id === 'incorporationdate') {
      indexDoi = index
    } else if (field.id === 'registrationnumber') {
      indexRegnum = index
    }
  })
  // if both fields were found AND regnum is AFTER
  if (indexDoi >= 0 && indexRegnum >= 0 && indexRegnum > indexDoi) {
    const doiField = fields[indexDoi]
    const regNumField = fields[indexRegnum]

    fields[indexDoi] = regNumField
    fields[indexRegnum] = doiField
  }
  return fields
}
