// BVD items
export const REGISTERED_NAME = 'companyName'
export const INCORPORATION_DATE = 'incorporationDate'
export const VAT_NUMBER = 'vatNumber'

export const fieldsAllowedToGetBvdData = {
  REGISTERED_NAME,
  INCORPORATION_DATE,
  VAT_NUMBER,
}
