<template>
  <button
    class="app-toggle-button"
    :class="{ on: value }"
    type="button"
    role="switch"
    aria-label="toggle switch"
    :aria-checked="value"
    :aria-disabled="$attrs.disabled"
    @click="clicked"
  >
    <div class="knob">
      <AppRipple class="halo" md-centered />
      <span class="circle" />
    </div>
  </button>
</template>

<script>
export default {
  name: 'AppInputToggle',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { attrs, emit }) {
    const clicked = () => {
      if (!attrs.disabled) {
        emit('input', !props.value)
      }
    }
    return { clicked }
  },
}
</script>

<style lang="postcss" scoped>
.app-toggle-button {
  @apply w-10 h-6 bg-tertiary-text p-0.5 rounded-full;
  .knob {
    @apply relative transform transition ease-in-out duration-180;
    .halo {
      @apply absolute w-10 h-10 bg-gray-lighter rounded-full;
      @apply transform scale-0 transition ease-in-out duration-180;
      top: -10px;
      left: -10px;
      z-index: -10;
    }
    .circle {
      @apply block w-5 h-5 bg-white border border-gray-light rounded-full z-10 pointer-events-none;
    }
  }
  &.on {
    @apply bg-green;
    .knob {
      @apply translate-x-4;
      .halo {
        @apply bg-green-lighter;
        .md-ripple-wave {
          @apply bg-green;
        }
      }
    }
  }
  &:disabled {
    @apply cursor-not-allowed bg-gray-light;
    .circle {
      background: linear-gradient(rgba(0, 17, 51, 0.05), rgba(0, 17, 51, 0.05)),
        linear-gradient(#fff, #fff);
      @apply border-gray-light;
    }
  }
  &:not(:disabled) {
    &:hover .halo,
    &:focus .halo {
      @apply scale-100;
    }
  }
  &:focus {
    @apply outline-none;
  }
}
</style>
