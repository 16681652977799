import { useDynamicForm } from '@/forms/useDynamicForm'
import CorporateFieldGroup from '@/models/CorporateRegistration/CorporateFieldGroup/app'
import AppDynamicFormField from '@/models/DynamicField/AppDynamicFormField'
import { useFieldComponentHelper, useCorporateFieldPropsHelper } from '@/composables/useFormHelpers'
import { createFieldValidation } from '@/forms/CorporateRegistrationForm'
import { computed, reactive } from '@vue/composition-api'
import { useValidation } from 'vue-composable'
import { useDashboardStore } from '@/stores/dashboard'
export const useCompanyDetailsForm = () => {
  const { isLoading, formValidation, formFields, hasFormFields, isFormInvalid } = useDynamicForm()

  const setup = (fields) => {
    isLoading.value = true
    // initialize object can be done at API call
    const xeFields = new CorporateFieldGroup(fields)

    const validationData = {}

    const formData = xeFields.fields.map((field) => {
      const result = new AppDynamicFormField({
        field: field,
        value: getCompanyDetailsValue(field.id),
        hidden: false,
        disabled: false,
        props: useCorporateFieldPropsHelper(field),
        component: useFieldComponentHelper(field.type),
      })

      validationData[result.name] = createFieldValidation(result)
      validationData[result.name].required
        ? (validationData[result.name].required.$message = result.props.otherPlaceholder)
        : null
      return reactive(result)
    })

    formValidation.value = useValidation(validationData)

    for (const formField of formData) {
      formField.validation = formValidation.value[formField.name]
      if (formField.parent) {
        formField.parent = formData.find((item) => item.id === formField.parent)
      }
      if (formField.value) {
        formField.validation.$dirty = true
      }
    }

    isLoading.value = false
    formFields.value = formData
  }

  const getCompanyDetailsValue = (id) => {
    const dashboardStore = useDashboardStore()
    return dashboardStore.companyDetails[id] !== null
      ? dashboardStore.companyDetails[id]
      : undefined
  }

  return {
    setup,
    hasFormFields,
    isFormInvalid,
    isLoading,
    formFields: computed(() => formFields.value),
  }
}
