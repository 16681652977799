<template>
  <Component :is="renderComponent">
    <AppButton
      :loading="loading"
      :type="buttonType"
      :disabled="buttonDisabled"
      @click="$emit('click')"
    >
      {{ buttonLabel }}
    </AppButton>
  </Component>
</template>

<script>
import { computed } from '@vue/composition-api'

import AppCardFooter from '@/components/AppCardFooter/AppCardFooter'
import AppButton from '@/components/AppButton/AppButton'

export default {
  components: {
    AppCardFooter,
    AppButton,
  },
  props: {
    buttonLabel: {
      type: String,
      default: 'button',
    },
    buttonType: {
      type: String,
      default: 'button',
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isCardFooter: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const renderComponent = computed(() => {
      return props.isCardFooter ? 'AppCardFooter' : 'div'
    })

    return {
      renderComponent,
    }
  },
}
</script>

<style lang="postcss" scoped></style>
